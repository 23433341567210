(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _outsideClick = _interopRequireDefault(require("../helpers/outsideClick.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function btnHeaderMobile() {
  // btn mobile
  var headerBtnMobile = document.querySelector('[data-header="btn-mobile"]');
  if (headerBtnMobile) {
    var handleClickHeaderBtn = function handleClickHeaderBtn(event) {
      event.preventDefault();
      html.classList.toggle('overflow-mobile');
      headerNav.classList.toggle('active');
      headerBtnMobile.classList.toggle('active');
      headerOverlay.classList.toggle('active');
      (0, _outsideClick["default"])({
        element: headerNav,
        events: ['click'],
        callback: function callback() {
          html.classList.remove('overflow-mobile');
          headerNav.classList.remove('active');
          headerBtnMobile.classList.remove('active');
          headerOverlay.classList.remove('active');
        }
      });
    };
    var html = document.documentElement;
    var headerNav = document.querySelector('[data-header="nav"]');
    var headerOverlay = document.querySelector('[data-header="overlay"]');
    headerBtnMobile.addEventListener('click', handleClickHeaderBtn);
  }
}
var _default = exports["default"] = btnHeaderMobile;

},{"../helpers/outsideClick.js":8}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function faq() {
  var btns = document.querySelectorAll('[data-faq="btn"]');
  var responses = document.querySelectorAll('[data-faq="response"]');
  if (btns.length && responses.length) {
    var toggleFaqQuestion = function toggleFaqQuestion(indexTarget) {
      btns[indexTarget].classList.toggle('active');
      responses[indexTarget].classList.toggle('active');
    };
    btns.forEach(function (btn, index) {
      btn.addEventListener('click', function () {
        return toggleFaqQuestion(index);
      });
    });
  }
}
var _default = exports["default"] = faq;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _formDataToJSON = _interopRequireDefault(require("../helpers/formDataToJSON.js"));
var _toastAlert = _interopRequireDefault(require("./toastAlert.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// FORM - SUBMIT
function footerNewsletter($) {
  var form = document.querySelector('[data-newsletter="form"]');
  if (form) {
    var validateContactForm = function validateContactForm(form) {
      var inputs = form.querySelectorAll('input');
      var isAllInputsFilled = true;

      // check all inputs is empty
      inputs.forEach(function (elementForm) {
        if (elementForm.value === '' && elementForm.required && !elementForm.disabled) {
          elementForm.classList.add('invalid');
          setTimeout(function () {
            return elementForm.classList.remove('invalid');
          }, 2000);
          isAllInputsFilled = false;
        }
      });
      if (!isAllInputsFilled) {
        (0, _toastAlert["default"])({
          status: 'error',
          message: 'Preencha os campos obrigatórios!'
        });
        return isAllInputsFilled;
      }

      // check email
      var inputEmail = form.querySelector('[data-form="input-email"]');
      var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
      if (!isValidEmail) {
        (0, _toastAlert["default"])({
          status: 'error',
          message: 'Informe um e-mail válido!'
        });
        inputEmail.classList.add('invalid');
        setTimeout(function () {
          return inputEmail.classList.remove('invalid');
        }, 2000);
        isAllInputsFilled = false;
        return isAllInputsFilled;
      }
      return isAllInputsFilled;
    };
    var submitContactForm = function submitContactForm() {
      var elementsForm = form.querySelectorAll('input');
      var btn = form.querySelector('[data-newsletter="btn"]');
      $.ajax({
        url: form.action,
        type: 'POST',
        data: (0, _formDataToJSON["default"])(new FormData(form)),
        contentType: 'application/json',
        dataType: 'json',
        beforeSend: function beforeSend() {
          var inputData = $('[name="data"]');
          var inputEvent = $('[name="event"]');
          btn.classList.add('loading');
          inputData.val(new Date().toLocaleString('pt-BR'));
          inputEvent.val('blog-page');
        },
        success: function success(_ref) {
          var responseJSON = _ref.responseJSON;
          var msgResponse = (responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.msg) || 'Cadastro realizado com sucesso!';
          elementsForm.forEach(function (elementForm) {
            elementForm.value = '';
          });
          (0, _toastAlert["default"])({
            status: 'success',
            message: msgResponse
          });
        },
        complete: function complete() {
          btn.classList.remove('loading');
        },
        error: function error(_ref2) {
          var responseJSON = _ref2.responseJSON;
          var msgResponse = (responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.msg) || 'Ops! Erro ao cadastrar os dados, tente novamente!';
          (0, _toastAlert["default"])({
            status: 'error',
            message: msgResponse
          });
        }
      });
    };
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      if (validateContactForm(form)) {
        submitContactForm();
      }
    });
  }
}
var _default = exports["default"] = footerNewsletter;

},{"../helpers/formDataToJSON.js":7,"./toastAlert.js":6}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function footerScrollTop() {
  var link = document.querySelector('[data-footer="scroll-top"]');
  if (link) {
    var handleClickFooterLink = function handleClickFooterLink(event) {
      event.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
    link.addEventListener('click', handleClickFooterLink);
  }
}
var _default = exports["default"] = footerScrollTop;

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function headerFixedOnScroll() {
  var header = document.querySelector('[data-header="header"]');
  if (header) {
    var toggleHeaderFixed = function toggleHeaderFixed() {
      var scrollWindow = window.scrollY;
      var isToActiveHeader = scrollWindow > scrollTop;
      if (isToActiveHeader) {
        header.classList.add('fixed');
      } else {
        header.classList.remove('fixed');
      }
    };
    var isMediaWidthMin992px = window.matchMedia('(min-width: 992px)').matches;
    var scrollTop = isMediaWidthMin992px ? 200 : 150;
    toggleHeaderFixed();
    window.addEventListener('scroll', toggleHeaderFixed);
  }
}
var _default = exports["default"] = headerFixedOnScroll;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function initToast(_ref) {
  var status = _ref.status,
    message = _ref.message;
  var toastContainer = document.querySelector('.toast-container');
  var toast = createToast(status, message);
  toastContainer.appendChild(toast);
  handleClickCloseToast();
  removeToast(toast);
}
function createToast(statusToast, messageToast) {
  var toastElement = document.createElement('div');
  toastElement.classList.add('toast-element', statusToast);
  toastElement.innerHTML = "\n      <div class=\"toast-message\">\n        ".concat(messageToast, "\n      </div>\n      <button class=\"toast-close no-config\" data-toast=\"close\">\n        <svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:svgjs=\"http://svgjs.com/svgjs\" width=\"12\" height=\"11\" x=\"0\" y=\"0\" viewBox=\"0 0 329.26933 329\" style=\"enable-background:new 0 0 512 512\" xml:space=\"preserve\" class=\"\">\n          <g>\n            <path xmlns=\"http://www.w3.org/2000/svg\" d=\"m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0\" fill=\"#ffffff\" data-original=\"#fff\" class=\"\"></path>\n          </g>\n        </svg>\n      </button>\n      <div class=\"toast-progress\"></div>\n    ");
  return toastElement;
}
function removeToast(toastElement, clickedBtnClose) {
  setTimeout(function () {
    toastElement.classList.add('hide');
  }, clickedBtnClose ? 0 : 5000);
  setTimeout(function () {
    toastElement.classList.add('removing-content');
  }, clickedBtnClose ? 500 : 5500);
  setTimeout(function () {
    toastElement.remove();
  }, clickedBtnClose ? 2500 : 8000);
}
function handleClickCloseToast() {
  var btnsCloseToast = document.querySelectorAll('[data-toast="close"]');
  if (btnsCloseToast.length) {
    btnsCloseToast.forEach(function (btn) {
      btn.addEventListener('click', function () {
        var toastTargetClicked = this.parentElement;
        removeToast(toastTargetClicked, true);
      });
    });
  }
}
var _default = exports["default"] = initToast;

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function formDataToJSON(data) {
  var method = function method(object, pair) {
    var keys = pair[0].replace(/\]/g, '').split('[');
    var key = keys[0];
    var value = pair[1];
    if (keys.length > 1) {
      var i, x, segment;
      var last = value;
      var type = isNaN(keys[1]) ? {} : [];
      value = segment = object[key] || type;
      for (i = 1; i < keys.length; i++) {
        x = keys[i];
        if (i == keys.length - 1) {
          if (Array.isArray(segment)) {
            segment.push(last);
          } else {
            segment[x] = last;
          }
        } else if (segment[x] == undefined) {
          segment[x] = isNaN(keys[i + 1]) ? {} : [];
        }
        segment = segment[x];
      }
    }
    object[key] = value;
    return object;
  };
  var object = Array.from(data).reduce(method, {});
  return JSON.stringify(object);
}
var _default = exports["default"] = formDataToJSON;

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function outsideClick(_ref) {
  var element = _ref.element,
    events = _ref.events,
    callback = _ref.callback;
  var html = document.documentElement;
  var outside = 'data-outside';
  if (!element.hasAttribute(outside)) {
    events.forEach(function (userEvent) {
      setTimeout(function () {
        html.addEventListener(userEvent, handleOutsideClick);
      });
    });
    element.setAttribute(outside, '');
  }
  function handleOutsideClick(event) {
    if (!element.contains(event.target)) {
      element.removeAttribute(outside);
      events.forEach(function (userEvent) {
        html.removeEventListener(userEvent, handleOutsideClick);
      });
      callback();
    }
  }
}
var _default = exports["default"] = outsideClick;

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function updateURLParams(_ref) {
  var _ref$nameParam = _ref.nameParam,
    nameParam = _ref$nameParam === void 0 ? null : _ref$nameParam,
    _ref$valueParam = _ref.valueParam,
    valueParam = _ref$valueParam === void 0 ? null : _ref$valueParam;
  var queryParams = new URLSearchParams(window.location.search);
  valueParam ? queryParams.set(nameParam, valueParam) : queryParams["delete"](nameParam);
  history.pushState(null, null, '?' + queryParams.toString());
}
var _default = exports["default"] = updateURLParams;

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function blogAddParagraphEmptyClass() {
  var blogDetailsMain = document.querySelector('[blog-details="main"]');
  if (blogDetailsMain) {
    var allParagraphs = blogDetailsMain.querySelectorAll('p');
    allParagraphs.length && allParagraphs.forEach(function (paragraph) {
      if (paragraph.innerHTML === '  ') {
        paragraph.classList.add('paragraph-space');
      }
    });
  }
}
var _default = exports["default"] = blogAddParagraphEmptyClass;

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _updateURLParams = _interopRequireDefault(require("../helpers/updateURLParams.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function blogFilterPosts() {
  var selectCategory = document.querySelector('[data-blog-search="category"]');
  var inputSearchTheme = document.querySelector('[data-blog-search="theme"]');
  var btnSearchTheme = document.querySelector('[data-blog-search="btn-theme"]');
  if (selectCategory && inputSearchTheme && btnSearchTheme) {
    var blogSearchPostByCategory = function blogSearchPostByCategory() {
      var valueCategory = this.value;
      (0, _updateURLParams["default"])({
        nameParam: 'categoria',
        valueParam: valueCategory
      });
      (0, _updateURLParams["default"])({
        nameParam: 'scroll',
        valueParam: ''
      });
      location.reload();
    };
    var blogSearchPostByTheme = function blogSearchPostByTheme() {
      var valueInput = inputSearchTheme.value;
      (0, _updateURLParams["default"])({
        nameParam: 'tema',
        valueParam: valueInput
      });
      (0, _updateURLParams["default"])({
        nameParam: 'scroll',
        valueParam: ''
      });
      location.reload();
    };
    var handleEnter = function handleEnter(_ref) {
      var key = _ref.key;
      if (key === 'Enter') {
        btnSearchTheme.click();
      }
    };
    selectCategory.addEventListener('change', blogSearchPostByCategory);
    btnSearchTheme.addEventListener('click', blogSearchPostByTheme);
    inputSearchTheme.onkeypress = handleEnter;
  }
}
var _default = exports["default"] = blogFilterPosts;

},{"../helpers/updateURLParams.js":9}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _updateURLParams = _interopRequireDefault(require("../helpers/updateURLParams.js"));
var _blogScrollToFiltersSection = _interopRequireDefault(require("./blogScrollToFiltersSection.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function blogIdentifyParams() {
  function createClearFiltersLink() {
    // OBS: 'popstate' irá ser ativado APENAS quando a URL + Parametros
    // forem modificados de forma manual (função 'createClearFiltersLink' está fazendo isso).
    // isso só está acontendo na página de blog
    var boxCategory = document.querySelector('[data-blog-search="box-category"]');
    var boxTheme = document.querySelector('[data-blog-search="box-theme"]');
    var categoryParam = new URLSearchParams(window.location.search).get("categoria");
    var themeParam = new URLSearchParams(window.location.search).get("tema");
    if (categoryParam) {
      var linkText = document.createElement("a");
      linkText.setAttribute("href", "#");
      linkText.setAttribute("class", "blog-filters-clear");
      linkText.dataset.blogSearch = "clear-category";
      linkText.innerText = "limpar filtro de categoria";
      boxCategory.appendChild(linkText);
      linkText.addEventListener("click", function (event) {
        event.preventDefault();
        clearFilter("category");
      });
    }
    if (themeParam) {
      var _linkText = document.createElement("a");
      _linkText.setAttribute("href", "#");
      _linkText.setAttribute("class", "blog-filters-clear");
      _linkText.dataset.blogSearch = "clear-theme";
      _linkText.innerText = "limpar filtro de tema";
      boxTheme.appendChild(_linkText);
      _linkText.addEventListener("click", function (event) {
        event.preventDefault();
        clearFilter("theme");
      });
    }
  }
  function clearFilter(type) {
    if (type === "category") {
      (0, _updateURLParams["default"])({
        nameParam: "categoria",
        valueParam: ""
      });
      (0, _updateURLParams["default"])({
        nameParam: "scroll",
        valueParam: "trueForce"
      });
      location.reload();
    }
    if (type === "theme") {
      (0, _updateURLParams["default"])({
        nameParam: "tema",
        valueParam: ""
      });
      (0, _updateURLParams["default"])({
        nameParam: "scroll",
        valueParam: "trueForce"
      });
      location.reload();
    }
  }
  function blogHandleChangeURL() {
    var pathname = window.location.pathname;

    // 01 verificar se é a página de blog
    if (pathname.includes("blog")) {
      // 02 verificar se os parametros mudaram
      // vai ser o 'search' e 'valueInput'
      var checkParamAndURLValuesIsDiferent = function checkParamAndURLValuesIsDiferent() {
        var categoryParam = new URLSearchParams(window.location.search).get("categoria");
        var themeParam = new URLSearchParams(window.location.search).get("tema");
        var selectCategory = document.querySelector('[data-blog-search="category"]');
        var selectCategoryValue = selectCategory.value;
        var inputSearchTheme = document.querySelector('[data-blog-search="theme"]');
        var inputSearchThemeValue = inputSearchTheme.value;
        if ((categoryParam || selectCategoryValue) && categoryParam !== selectCategoryValue) {
          location.reload();
          return;
        }
        if ((themeParam || inputSearchThemeValue) && themeParam !== inputSearchThemeValue) {
          location.reload();
        }
      };
      var checkIsBlogRootPageAndCheckParams = function checkIsBlogRootPageAndCheckParams() {
        // 01 verificar se é a página de blog
        var categoryParam = new URLSearchParams(window.location.search).get("categoria");
        var themeParam = new URLSearchParams(window.location.search).get("tema");

        // verificar caso não tenha nenhum parametro
        if (!categoryParam && !themeParam) {
          var selectCategoryValue = document.querySelector('[data-blog-search="category"]').value;
          var inputSearchThemeValue = document.querySelector('[data-blog-search="theme"]').value;
          var isCategorySelectValueEmpty = !Boolean(selectCategoryValue);
          var isThemeInputValueEmpty = !Boolean(inputSearchThemeValue);

          // e verficar se os valores dos input/select
          // estão preenchidos com algum valor
          if (!isCategorySelectValueEmpty || !isThemeInputValueEmpty) {
            location.reload();
          }
        }
      };
      checkParamAndURLValuesIsDiferent();
      checkIsBlogRootPageAndCheckParams();
      (0, _blogScrollToFiltersSection["default"])();
    }
  }
  createClearFiltersLink();
  window.addEventListener("popstate", blogHandleChangeURL);
}
var _default = exports["default"] = blogIdentifyParams;

},{"../helpers/updateURLParams.js":9,"./blogScrollToFiltersSection.js":13}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function blogScrollToFiltersSection() {
  var pathname = window.location.pathname;
  var isBlogPage = pathname.includes('blog');
  var scrollParam = new URLSearchParams(window.location.search).get('scroll');
  var categoryParam = new URLSearchParams(window.location.search).get('categoria');
  var themeParam = new URLSearchParams(window.location.search).get('tema');
  var blogSection = document.querySelector('[data-blog="main"]');
  if (isBlogPage && categoryParam && !themeParam && scrollParam && blogSection) {
    blogSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    return;
  }
  if (scrollParam === 'trueForce') {
    blogSection.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}
var _default = exports["default"] = blogScrollToFiltersSection;

},{}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function blogSeeMorePosts($) {
  var btnSeeMore = $('.blog-see-more');
  if (btnSeeMore.length) {
    var createBlogItemElement = function createBlogItemElement(data) {
      var blogList = document.querySelector('.blog-list');
      function createCategoriesItemElement(categoriesList) {
        return categoriesList.map(function (_ref) {
          var name = _ref.name,
            slug = _ref.slug;
          return "<li>\n                <a href=\"".concat(baseURL, "/blog/?categoria=").concat(slug, "&scroll=true\" class=\"blog-item-category-name\" data-blog-category=\"name\">\n                  ").concat(name, "\n                </a>\n              </li>");
        }).join('\n');
      }
      blogList.innerHTML += data.map(function (_ref2) {
        var categoria = _ref2.categoria,
          imagem = _ref2.imagem,
          link = _ref2.link,
          resumo = _ref2.resumo,
          titulo = _ref2.titulo;
        return "<div class=\"blog-item\">\n            <a href=\"".concat(link, "\" class=\"blog-item-thumb\">\n              <img src=\"").concat(imagem, "\" alt=\"\">\n            </a>\n            <div class=\"blog-item-content\">\n              <ul class=\"blog-item-category\">\n                ").concat(categoria.length ? createCategoriesItemElement(categoria) : '', "\n              </ul>\n              <a href=\"").concat(link, "\" class=\"blog-item-title\">").concat(titulo, "</a>\n              <div class=\"blog-item-description\">\n                <p>").concat(resumo, "</p>\n              </div>\n              <a href=\"").concat(link, "\" class=\"blog-item-link link-arrow\">\n                Leia mais\n                <svg width=\"16\" height=\"12\" viewBox=\"0 0 16 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                  <path d=\"M15 6H1\" stroke=\"#094571\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>\n                  <path d=\"M10 11L15 6\" stroke=\"#094571\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>\n                  <path d=\"M10 1L15 6\" stroke=\"#094571\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>\n                </svg>\n              </a>\n            </div>\n          </div>");
      }).join('\n');
    };
    btnSeeMore.on('click', function () {
      var init = $(this).data('post');
      var limit = $(this).data('limit');
      var category = $(this).data('category');
      $.ajax({
        url: baseURL + '/wp-json/toch/v1/post/get',
        data: {
          init: init,
          limit: limit,
          category: category
        },
        dataType: 'json',
        type: 'post',
        beforeSend: function beforeSend() {
          btnSeeMore.addClass('loading');
        },
        success: function success(_ref3) {
          var data = _ref3.data;
          if (data && data.length) {
            createBlogItemElement(data);
            btnSeeMore.data('post', Number(btnSeeMore[0].dataset.post) + Number(data.length));
            btnSeeMore[0].dataset.post = Number(btnSeeMore[0].dataset.post) + Number(data.length);
            var postsShowingValue = btnSeeMore[0].dataset.post;
            var postsLimitValue = btnSeeMore[0].dataset.limit;
            if (postsShowingValue === postsLimitValue) {
              btnSeeMore.addClass('hide');
            }
          }
        },
        complete: function complete() {
          setTimeout(function () {
            btnSeeMore.removeClass('loading');
          }, 500);
        },
        error: function error(XMLHttpRequest, textStatus, errorThrown) {
          // console.log(errorThrown);
        }
      });
    });
  }
}
var _default = exports["default"] = blogSeeMorePosts;

},{}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function blogSinglePostsSlider($) {
  var othersBlogPostsList = $('[data-blog="others-list"]');
  if (othersBlogPostsList.length) {
    othersBlogPostsList.slick({
      slidesToShow: 1,
      infinite: true,
      centerMode: true,
      arrows: true,
      prevArrow: "<button class=\"btn-prev\" type=\"button\">\n        <svg width=\"14\" height=\"11\" viewBox=\"0 0 14 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path d=\"M1.6665 5.49998H12.3332\" stroke=\"#0897E9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n          <path d=\"M5.6665 9.5L1.6665 5.5L5.6665 1.5\" stroke=\"#0897E9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n        </svg>\n      </button>",
      nextArrow: "<button class=\"btn-next\" type=\"button\">\n        <svg width=\"14\" height=\"11\" viewBox=\"0 0 14 11\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path d=\"M12.3332 5.5H1.6665\" stroke=\"#0897E9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n          <path d=\"M8.3335 1.5L12.3335 5.5L8.3335 9.5\" stroke=\"#0897E9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n        </svg>\n      </button>"
    });
  }
}
var _default = exports["default"] = blogSinglePostsSlider;

},{}],16:[function(require,module,exports){
"use strict";

var _headerFixedOnScroll = _interopRequireDefault(require("./modules/global/headerFixedOnScroll.js"));
var _btnHeaderMobile = _interopRequireDefault(require("./modules/global/btnHeaderMobile.js"));
var _footerNewsletter = _interopRequireDefault(require("./modules/global/footerNewsletter.js"));
var _footerScrollTop = _interopRequireDefault(require("./modules/global/footerScrollTop.js"));
var _faq = _interopRequireDefault(require("./modules/global/faq.js"));
var _blogAddParagraphEmptyClass = _interopRequireDefault(require("./modules/pages/blogAddParagraphEmptyClass.js"));
var _blogIdentifyParams = _interopRequireDefault(require("./modules/pages/blogIdentifyParams.js"));
var _blogFilterPosts = _interopRequireDefault(require("./modules/pages/blogFilterPosts.js"));
var _blogSeeMorePosts = _interopRequireDefault(require("./modules/pages/blogSeeMorePosts.js"));
var _blogSinglePostsSlider = _interopRequireDefault(require("./modules/pages/blogSinglePostsSlider.js"));
var _blogScrollToFiltersSection = _interopRequireDefault(require("./modules/pages/blogScrollToFiltersSection.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// Quando ALGUMAS partes da página carregar
jQuery(document).ready(function ($) {
  (0, _blogAddParagraphEmptyClass["default"])();
  (0, _headerFixedOnScroll["default"])();
  (0, _btnHeaderMobile["default"])();
  (0, _faq["default"])();
  (0, _footerNewsletter["default"])($);
  (0, _footerScrollTop["default"])();
  (0, _blogIdentifyParams["default"])();
  (0, _blogFilterPosts["default"])();
  (0, _blogSeeMorePosts["default"])($);
  (0, _blogSinglePostsSlider["default"])($);
  (0, _blogScrollToFiltersSection["default"])();
});

// Quando TODA a página carregar
window.addEventListener("load", function () {
  var body = document.body;
  body.classList.add("page-loaded");
  AOS.init({
    once: true,
    duration: 900,
    offset: 200
  });
});

},{"./modules/global/btnHeaderMobile.js":1,"./modules/global/faq.js":2,"./modules/global/footerNewsletter.js":3,"./modules/global/footerScrollTop.js":4,"./modules/global/headerFixedOnScroll.js":5,"./modules/pages/blogAddParagraphEmptyClass.js":10,"./modules/pages/blogFilterPosts.js":11,"./modules/pages/blogIdentifyParams.js":12,"./modules/pages/blogScrollToFiltersSection.js":13,"./modules/pages/blogSeeMorePosts.js":14,"./modules/pages/blogSinglePostsSlider.js":15}]},{},[16]);
